import crypto from "crypto-js";
import router from "next/router";

export default function useAuth() {
  if (typeof Storage !== "undefined") {
    const usrpersist = `${localStorage.getItem("_usrpersist")}`;
    const token = `${localStorage.getItem("_AknT")}`;

    const bytes =
      usrpersist !== "null"
        ? crypto.AES.decrypt(usrpersist, "!@#109Tyuuryfqowp085rjf{}[])_+.//||")
        : "";

    const user = bytes ? JSON.parse(bytes.toString(crypto.enc.Utf8)) : null;
    return {
      token,
      // userType,
      user,
    };
  } else {
    return {
      error: "LocalStorage not supported",
    };
  }
}

export function logout(props) {
  localStorage.removeItem("_usrpersist");
  localStorage.removeItem("_AknT");
  localStorage.removeItem("checkoutOrders");

  !!props?.reload
    ? router.reload()
    : router.push(`/login?redirect=${window.location.pathname}`);
}
