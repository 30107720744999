import React from "react";
import App from "next/app";
// import { DefaultSeo } from 'next-seo';
import Head from "next/head";
// import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "../src/theme";
// import 'tailwindcss/tailwind.css'
import '../styles/globals.css'
import '../styles.scss';
import 'react-quill/dist/quill.snow.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import CartContext from '../components/cartContext';
// import GlobalState from '../context/search';
// import Layout from '../components/Layout';
// import PushNotif from '../libs/usePushNotif';
import axios from 'axios';
// import auth from '../libs/auth';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from '../context/authenticate';
import crypto from 'crypto-js';
import Router from "next/router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";


Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());


// const {
//     urlBase64ToUint8Array,
//     askUserPermission,
//     getSubscription,
//     registerServiceWorker } = PushNotif();

const PUBLIC_KEY = 'BGYr_gE5ZAqAYe85byEbtJ1SLBJmzQwdLMJrEm9qI-waEWS_UxEdlScW_LgMzoto2B9XABeTQtzfMndgQNSQk08'



export default class MyApp extends App {

    state = {
        cart: [],
        carttotal: 0,
        cartSubTotal: 0,
        products: [],
        user: {},
        _user: [],
        filterMode: false,
        checkoutOrders: [],
        filtered: false,
        sub: null,
        displaySearch: false,
        orders: [],
        orderRequest: [],
        // variety: [],
        shippingInfo: undefined,
        orderTotal: 0,
        categories: [],
        savedProduct: [],
    }

    componentDidMount() {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector("#jss-server-side");
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
        // this.getCategories();
        this.updateFromLocalStorage()
    }

    updateFromLocalStorage = () => {
        const usrpersist = localStorage.getItem("_usrpersist")
            ? localStorage.getItem("_usrpersist")
            : "";
        const getProd = localStorage.getItem("autoProduct")
            ? localStorage.getItem("autoProduct")
            : "";

        const bytes = usrpersist
            ? crypto.AES.decrypt(usrpersist, "!@#109Tyuuryfqowp085rjf{}[])_+.//||")
            : null;
        const _user = bytes ? JSON.parse(bytes.toString(crypto.enc.Utf8)) : null;



        const Pbytes = getProd
            ? crypto.AES.decrypt(getProd, "!@#109Tyuuryfqowp085rjf{}[])_+.//||")
            : null;

        const savedProduct = Pbytes?.words?.length
            ? JSON.parse(Pbytes.toString(crypto.enc.Utf8))
            : null;

        if (_user) {
            this.setState(
                () => {
                    return {
                        _user,
                        savedProduct,
                    };
                }
            );
        }
    };

    getCategories = async () => {
        const res = await fetch(`${process.env.BACKEND_URL}/all-categories`);
        const data = await res.json();
        this.setState({ categories: data });
        // console.log(data);
    };

    AutoSaveProduct = (product) => {
        // localStorage.setItem('autoProduct', JSON.stringify([]));
        const ciphertext = crypto.AES.encrypt(
            JSON.stringify(product),
            "!@#109Tyuuryfqowp085rjf{}[])_+.//||"
        ).toString();
        localStorage.setItem("autoProduct", ciphertext);
        // localStorage.setItem('autoProduct', JSON.stringify(product));
        this.setState({ savedProduct: product });
    };

    ClearAutoSavedProduct = () => {
        // this.setState({ user: authUser });
        localStorage.removeItem("autoProduct");
        this.setState({
            savedProduct: [],
        });
    };

    saveAuth = (authUser) => {
        this.setState({ user: authUser });
        localStorage.setItem('user', JSON.stringify(authUser));
    }

    saveCategoryProduct = ({ product }) => {
        this.setState({ products: product })
    }

    setSearchComponent = (bool) => {
        this.setState({ displaySearch: bool })
    }

    saveCartToStorage = (theProduct) => {
        this.setState(() => {
            return {
                cart: [...this.state.cart, ...theProduct],
            };
        }, () => {
            this.addTotals()
            // console.log(this.state.cart);
        });
        localStorage.setItem('cart', JSON.stringify([...this.state.cart, ...theProduct]));
    }

    removeFromCart = (index) => {
        // console.log(index);
        let tempCart = [...this.state.cart];

        tempCart = tempCart.filter((each, i) => each.pid !== index);
        this.updateCart(tempCart);
    }


    updateCart = (newCart) => {
        localStorage.setItem('cart', JSON.stringify(newCart));
        this.setState({ cart: newCart }, () => this.addTotals());
    }

    // calculateTotal = (price) => {
    //     this.setState({ carttotal: this.state.carttotal + price });
    //     localStorage.setItem('total', JSON.stringify(this.state.carttotal + price));
    // }

    addTotals = async (shippingFee = 0) => {
        // console.log(this.state.cart);
        const initialValue = 0;
        const totalAmount = await this.state.cart.reduce(((a, b) => { return a + (b.product.price * b.quantity) }), initialValue)
        const total = totalAmount + shippingFee;
        this.setState(() => {
            return {
                cartSubTotal: totalAmount,
                carttotal: total,
            };
        });
        localStorage.setItem('total', JSON.stringify(total));
        localStorage.setItem('cartSubTotal', JSON.stringify(totalAmount));
    }

    updateTotal = (total) => {
        this.setState({ carttotal: total });
        // localStorage.setItem('total', JSON.stringify(total));
    }

    clearCart = () => {
        localStorage.setItem('total', 0);
        localStorage.setItem('cart', JSON.stringify([]));
        this.setState({
            cart: [],
            carttotal: 0,
        }, () => this.addTotals())
    }

    clearProductVariety = () => {
        localStorage.setItem('variety', JSON.stringify([]));
        this.setState({
            variety: [],
        })
    }

    getTotal = (orders) => {
        return orders.map(each => {
            const { product } = each;
            return parseInt(product.price, 10) * parseInt(each.approvedQty, 10)
        }).reduce((acc, ini) => {
            return ini + acc
        }, 0)
    }

    addReadyToPayOrders = (orders) => {
        this.setState({
            checkoutOrders: orders
        }, () => {
            localStorage.setItem('checkoutOrders', JSON.stringify(orders))
            this.setOrderTotal(orders)
        })
    }

    setOrderTotal = (orders) => {
        this.setState({
            orderTotal: this.getTotal(orders)
        })
    }

    getUserOrders = (token) => {
        if (token) {
            axios({
                method: 'get',
                url: `${process.env.BACKEND_URL}/orders`,
                headers: {
                    Authorization: token,
                }
            }).then(response => {
                // console.log(response)
                this.setState(() => {
                    return {
                        orders: response.data.orders
                    }
                })
            }).catch(error => console.log('error getting orders', error))
        }
    }

    saveShippingInfo = (shippingInfo) => {
        this.setState(() => {
            return { shippingInfo }
        })
    }

    clearCheckoutOrders = () => {
        this.setState(() => {
            return {
                checkoutOrders: []
            }
        }, () => localStorage.setItem('checkoutOrders', null))
    }

    moveToContextVariety = (variety) => {
        localStorage.setItem('variety', JSON.stringify([]));
        this.setState(() => {
            return {
                variety,
            };
        });
    }

    render() {
        const { Component, pageProps, router: { route } } = this.props;

        const thePage = route.split("/")[1];

        return (
            <>
                <Head>
                    <meta
                        name="viewport"
                        content="initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                        // content="minimum-scale=1, initial-scale=1, width=device-width,user-scalable=no, shrink-to-fit=no"
                    />
                    
                </Head>
                <ThemeProvider theme={theme}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CartContext.Provider value={{
                        cart: this.state.cart,
                        saveCartToStorage: this.saveCartToStorage,
                        user: this.state.user,
                        _user: this.state._user,
                        // addToCart: this.addToCart,
                        removeFromCart: this.removeFromCart,
                        addTotals: this.addTotals,
                        cartSubTotal: this.state.cartSubTotal,
                        carttotal: this.state.carttotal,
                        products: this.state.products,
                        updateCart: this.updateCart,
                        updateTotal: this.updateTotal,
                        filtered: this.filtered,
                        saveCategoryProduct: this.saveCategoryProduct,
                        filterMode: this.state.filterMode,
                        setSearchComponent: this.setSearchComponent,
                        displaySearch: this.state.displaySearch,
                        clearCart: this.clearCart,
                        addReadyToPayOrders: this.addReadyToPayOrders,
                        checkoutOrders: this.state.checkoutOrders,
                        getUserOrders: this.getUserOrders,
                        getRequestOrders: this.getRequestOrders,
                        orders: this.state.orders,
                        orderRequest: this.state.orderRequest,
                        shippingInfo: this.state.shippingInfo,
                        variety: this.state.variety,
                        // addVariety: this.addVariety,
                        saveShippingInfo: this.saveShippingInfo,
                        clearCheckoutOrders: this.clearCheckoutOrders,
                        clearProductVariety: this.clearProductVariety,
                        moveToContextVariety: this.moveToContextVariety,
                        setOrderTotal: this.setOrderTotal,
                        orderTotal: this.state.orderTotal,

                        getCategories: this.getCategories,
                        categories: this.state.categories,
                        AutoSaveProduct: this.AutoSaveProduct,
                        savedProduct: this.state.savedProduct,
                        ClearAutoSavedProduct: this.ClearAutoSavedProduct,
                    }}>
                        <CssBaseline />

                        {/* <GlobalState> */}
                        <AuthProvider thePage={thePage}>
                            <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                                {/* <Layout> */}
                                <Component {...pageProps} />
                                {/* </Layout> */}
                            </SnackbarProvider>
                        </AuthProvider>
                        {/* </GlobalState> */}
                    </CartContext.Provider>
                </ThemeProvider>
            </>
        );
    }
}