import React, { createContext, useState, useContext } from "react";
import api from "../services/api";
import authGetter from "../libs/auth";
import crypto from "crypto-js";
// import dashboard from '../pages/dashboard';
import { Router, useRouter } from "next/router";

const AuthContext = createContext({});

export const AuthProvider = (props) => {
  const router = useRouter();
  const { thePage } = props;
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //     async function loadUserFromStorage() {
  //         const token = authGetter()?.token;
  //         if (token !== 'null') {
  //             //get token from local storage and see if its valid
  //             api.defaults.headers.Authorization = token
  //             try {
  //                 const { data: user } = await api.get('account')
  //                 if (user) setUser(user.account);
  //                 setLoading(false)
  //             } catch (error) {
  //                 // console.log(error.response)
  //                 setLoading(false)
  //                 // not authorized or some network or technical error
  //             }
  //         }
  //     }
  //     loadUserFromStorage()
  // }, [])

  if (thePage === "dashboard" && !authGetter().token) {
    // setUser(null)
    // localStorage.setItem('_AknT', null)
    // localStorage.setItem('_usrpersist', null)
    // Router.push('/')
  }

  if ((thePage === "login" || thePage === "signup") && authGetter().user) {
    router.push("/dashboard");
  }

  const login = async (data) => {
    try {
      const response = await api.post("auth/login", data);
      const {
        data: { authToken },
      } = response;
      api.defaults.headers.Authorization = `${authToken}`;

      if (typeof Storage !== "undefined") {
        localStorage.setItem("_usrpersist", response.data.res);
        localStorage.setItem("_AknT", response.data.authToken); // auth token
      } else {
        console.log("LocalStorage not supported");
      }

      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const logout = () => {
    // setUser("");
    // localStorage.removeItem("_AknT");
    // localStorage.removeItem("_usrpersist");
    // Router.push("/login");

    logout();
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: !!user, user, login, loading, logout }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
